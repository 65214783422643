// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

global.$ = require("jquery");

require("jquery");
//require("widget")
require("jquery-ui");
require("popper");

Rails.start();
//Turbolinks.start()
ActiveStorage.start();

//require("channels")
//import "channels";

import "../js/services/uploader/vendor/jquery.ui.widget.js";
import "../js/services/uploader/jquery.fileupload.js";

import "../js/angular/angular.js";
import "../js/angular/angular-resource.js";
import "../js/angular/angular-sanitize.js";

window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");
//window.bootstrap = require('../js/bootstrap_js_files.js');

//import '../js/bootstrap_js_files.js'

//import { Modal } from 'bootstrap'
//import * as Bootstrap from 'bootstrap'

import "@fortawesome/fontawesome-free/js/all";

import "../js/services/jquery.scrollify.js";
//require("jquery-fileuploader/js/fileUploader.js")

//import ("blueimp-file-upload/js/jquery.fileupload.js")

window.B2cApp = angular.module("B2cApp", []);
require("js/controllers/WelcomeCtrl.js");
require("js/controllers/UploaderCtrl.js");
require("js/controllers/UploaderSoundCtrl.js");
require("js/controllers/PictureCtrl.js");
require("js/controllers/ChatCtrl.js");
require("js/controllers/YearbookCtrl.js");
require("js/controllers/GroupsCtrl.js");
require("js/controllers/PaymentCtrl.js");
require("js/controllers/AkashaCreateEditorCtrl.js");
require("js/controllers/FooterCtrl.js");
require("js/controllers/RegisterCtrl.js");

$(document).ready(function () {
  $(".group_menu_element").click(function () {
    window.location = $(this).find("a").attr("href");
    return false;
  });

  $(".scroller").scrollify();

  var tooltipList, tooltipTriggerList;

  tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );

  tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  //alert($("#test").html())

  //for (var i = 0; i < $('.preview_page').length; i++) {
  //
  //  var canvas = new fabric.Canvas($($('.preview_page')[i]));
  //
  //  var rect = new fabric.Rect({
  //      top : 100,
  //      left : 100,
  //      width : 60,
  //      height : 70,
  //      fill : 'red'
  //  });
  //
  //  canvas.add(rect);
  //
  //}
  //if ($("#wookmark1").length){
  //  var wookmark1 = new Wookmark('#wookmark1', {
  //      outerOffset: 10, // Optional, the distance to the containers border
  //      itemWidth: 210 // Optional, the width of a grid item
  //  });
  //}

  $("#new_picture2").fileupload({
    add: function (e, data) {
      data.progressBar = $(
        '<div class="progress" style="width: 300px"><div class="progress-bar"></div></div>'
      ).insertAfter("body");
      var options = {
        extension: data.files[0].name.match(/(\.\w+)?$/)[0], // set the file extension
        _: Date.now(), // prevent caching
      };

      //$.getJSON("/images/upload/cache/presign", options, function(result) {
      //  data.formData  = result.fields;
      //  data.url       = result.url;
      //  data.paramName = "file";
      //  data.submit();
      //});
    },

    progress: function (e, data) {
      var progress = parseInt((data.loaded / data.total) * 100, 10);
      var percentage = progress.toString() + "%";
      data.progressBar
        .find(".progress-bar")
        .css("width", percentage)
        .html(percentage);
    },

    done: function (e, data) {
      data.progressBar.remove();

      var image = {
        id: data.formData.key.match(/cache\/(.+)/)[1], // we have to remove the prefix part
        storage: "cache",
        metadata: {
          size: data.files[0].size,
          filename: data.files[0].name.match(/[^\/\\]+$/)[0], // IE return full path
          mime_type: data.files[0].type,
        },
      };

      var form = $(this).closest("form");
      var form_data = new FormData(form[0]);
      form_data.append($(this).attr("name"), JSON.stringify(image));

      $.ajax(form.attr("action"), {
        contentType: false,
        processData: false,
        data: form_data,
        method: form.attr("method"),
        dataType: "json",
        success: function (response) {
          var $img = $("<img/>", { src: response.image_url, width: 400 });
          var $div = $("<div/>").append($img);
          $("#photos").append($div);
        },
      });
    },
  });
  const accordion = $(".container .label");
  let i = 0;
  var self = $(this).parent();

  for (i = 0; i < accordion.length; i++) {
    accordion[i].addEventListener("click", function () {
      //this.classList.toggle("active");
      $(this).parent().toggleClass("active").next().toggle();
    });
  }

  const password_input = $(".password_input");
  let j = 0;
  for (j = 0; j < password_input.length; j++) {
    password_input[j].addEventListener("click", function () {
      var password = document.querySelector("." + $(this).attr("target_input"));
      var type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);

      $(this).find(".password_icon").toggle();
    });
  }

  //const togglePassword = document.querySelector('.password_input');
  //togglePassword.addEventListener('click', function (e) {
  //
  //  password = document.querySelector('.password_target_input');
  //  alert("ok")
  //  // toggle the type attribute
  //  type = password.getAttribute('type') === 'password' ? 'text' : 'password';
  //  password.setAttribute('type', type);
  //  // toggle the eye slash icon
  //  this.classList.toggle('fa-eye-slash');
  //});
  //$(function () {
  //    'use strict';
  //    // Change this to the location of your server-side upload handler:
  //    if ($('#new_picture').length){
  //      $('#new_picture').fileupload();
  //    }
  //});
});

$("#exampleModal").on("show.bs.modal", function (event) {
  var button = $(event.relatedTarget); // Button that triggered the modal
  var recipient = button.data("whatever"); // Extract info from data-* attributes
  // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
  // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
  var modal = $(this);
  modal.find(".modal-title").text("New message to " + recipient);
  modal.find(".modal-body input").val(recipient);
});

$(document).ready(function (event) {
  $("#Modal").click(function () {
    var myModal = new bootstrap.Modal(document.getElementById("myModal"));
    myModal.show();
  });
});

window.changeAcceptmail = function (el) {
  $.ajax({
    type: "POST",
    url: "/profiles/change_accept_email",
    data: {},
  });
  if ($(el).is(":checked")) {
    alert("Vous êtes bien inscrit à notre newsletter ");
  } else {
    alert("Vous êtes bien désinscrit de notre newsletter ");
  }
};
// function add_lidked_pictures() {
//   console.log("sds");
//   item.type = "Picture";
//   item.id = picture_id;
//   user.d = current.user_id;
//   $.post("/api/like_picture", {
//     current_user_id: user.id,
//     picture_id: picture.id,
//   });
// }

// function add_lidked_pictures() {
//   console.log("sds");
//   item.type = "Picture";
//   item.id = picture_id;
//   user.d = current.user_id;
//   $.post("/api/like_picture", {
//     current_user_id: user.id,
//     picture_id: picture.id,
//   });
// }
function verifier() {
  const elements = document.querySelectorAll(".verif");
  Array.from(elements).forEach((element, index) => {
    if (element.value != "") {
      element.style.border = "2px solid red";
    }
  });
}
function album_delete_click() {
  var album_down = document.getElementById("album_delete");
  album_down.remove();
}

let modalAlreadyShowed = false;

window.addEventListener("scroll", function (e) {
  if (!modalAlreadyShowed) {
    setTimeout(() => {
      //document.getElementById("modal").style.display = "block";
    }, 2000);
    modalAlreadyShowed = true;
  }
});

//document.getElementById("modal-close").addEventListener("click", function (e) {
//  document.getElementById("modal").style.display = "none";
//});

function myFunction() {
  var x = document.getElementById("snackbar");
  x.className = "show";
  setTimeout(function () {
    x.className = x.className.replace("show", "");
  }, 3000);
}

$("#menu_list li a").on("click", function () {
  $("li a.current").removeClass("current");
  $(this).addClass("current");
});

//$(document).ready(function () {
//  $(".product_albums").onclick(function () {
//    $(".tirages-panel").hide();
//  });
//});
//$(document).ready(function () {
//  //console.log("modal scrollable");
//  //var modalContent = $(".modal-scrollable");
//  //var modalHeight = modalContent.outerHeight();
//  //var windowHeight = $(window).height();
//  //
//  //$(window).on("scroll", function () {
//  //  var scrollPosition = $(this).scrollTop();
//  //  var offset = modalContent.offset().top;
//  //  var modalTop = offset - scrollPosition;
//  //
//  //  if (modalTop + modalHeight < windowHeight) {
//  //    modalContent.animate({ scrollTop: modalHeight }, 500);
//  //  }
//  //});
//});
