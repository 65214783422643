(function ($) {
  $.fn.scrollify = function () {
    function visuallyWrap(el) {
      var ul = $(el);

      ul.addClass("jq-scroller-mover");

      ul.wrap(
        '<div class="jq-scroller-wrapper"><div class="jq-scroller"></div></div>'
      );

      var prevNextBtn =
        '<i class="fa fa-arrow-circle-left jq-scroller-prev"></i><i id="nextBtn" class="fa fa-arrow-circle-right jq-scroller-next"></i>';

      var overlay =
        '<div class="jq-scroller-overlay"><div class="jq-overlay-item"><i class="jq-overlay-close fa fa-times-circle"></i><i class="fa fa-arrow-circle-left jq-scroller-overlay-prev"></i><i class="fa fa-arrow-circle-right jq-scroller-overlay-next"></i><div class="jq-overlay-content"></div></div></div>';

      var wrapper = ul.parents(".jq-scroller-wrapper");

      wrapper.append(prevNextBtn);

      wrapper.append(overlay);

      $(el)
        .find("li")
        .each(function (index, elem) {
          var li = $(elem);
          var a = li.find("a").first();
          a.addClass("jq-scroller-preview");
          li.addClass("jq-scroller-item");
          a.attr(
            "style",
            "background-image:url(" + a.children("img").attr("src") + ");"
          );
          li.append(
            '<div class="jq-scroller-caption">' +
              a.attr("data-title") +
              "</div>"
          );
        });
    }

    function attachEventHandlers(el) {
      $(".jq-scroller-overlay").hide();
      var w = $($(".jq-scroller-item")[0]).outerWidth() + "px";

      var animN = function () {
        var l =
          $(".jq-scroller-item").last().offset().left +
          $(".jq-scroller-item").last().width();
        var m =
          $(".jq-scroller-wrapper").offset().left +
          $(".jq-scroller-wrapper").width();
        if (l >= m) {
          return { left: "-=" + w };
        } else {
          return { left: "-=" + 0 };
        }
      };

      var animP = function () {
        if ($(".jq-scroller-mover").position().left == 0) {
          return { left: "+=" + 0 };
        } else {
          return { left: "+=" + w };
        }
      };
      var animDur = { duration: "medium" };

      var indexOfCurrentItem;

      $("#nextBtn").click(function () {
        $(".jq-scroller-mover").animate(animN(), animDur);
      });

      $(".jq-scroller-prev").click(function () {
        $(".jq-scroller-mover").animate(animP(), animDur);
      });

      $(".jq-scroller-overlay").click(function () {
        $(".jq-scroller-overlay").removeClass("active");
        $(".jq-overlay-content").empty();
      });

      $(".jq-scroller-item").click(function (event) {
        event.preventDefault();
        //$('.jq-scroller-overlay').show()
        //$('.jq-overlay-content').empty();
        //indexOfCurrentItem = $('.jq-scroller-item').index(this)
        //var contentLink =  $(this).children('.jq-scroller-preview')
        //var contentImageSrc = contentLink.attr('href')
        //var caption = contentLink.attr('data-title')
        //var maxHeight = ($(document).height() - 200)+"px";
        //
        //if (contentLink.attr('data-type') == 'iframe'){
        //	var content = $('<iframe src="'+contentImageSrc+'"  frameborder="0" allowfullscreen></iframe><p>'+caption+'</p>');
        //} else {
        //	var content = $('<img src="'+contentImageSrc+'" style="max-height:'+maxHeight+';"/><p>'+caption+'</p>');
        //}
        //content.appendTo($('.jq-overlay-content'));
        //$('.jq-scroller-overlay').addClass('active')
      });

      $(".jq-scroller-overlay-next").click(function (event) {
        event.stopPropagation();
        $($(".jq-scroller-item")[indexOfCurrentItem + 1]).trigger("click");
      });

      $(".jq-scroller-overlay-prev").click(function (event) {
        event.stopPropagation();
        $($(".jq-scroller-item")[indexOfCurrentItem - 1]).trigger("click");
      });
    }

    visuallyWrap(this);

    setTimeout(function () {
      attachEventHandlers(this);
    }, 2000);

    return this;
  };
  if (window.addEventListener) {
    window.addEventListener(
      "scroll",
      function () {
        //Scrollfen();
      },
      false
    );
  } else if (window.attachEvent) {
    window.attachEvent("onscroll", function () {
      //Scrollfen();
    });
  }
})(jQuery);
