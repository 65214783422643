import consumer from "./consumer"

//var message_list = ""
consumer.subscriptions.create("RoomChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to the room!")
    //document.getElementsByClassName('test')
    //message_list = document.getElementsByClassName("chat_message_insertion")[0];
    //console.log(message_list)
    //message_list.addEventListener('DOMNodeInserted', this.resetSroll(message_list))
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },
  //resetSroll(el){
  //  console.log("resetSroll---->")
  //  console.log(el)
  //  $(el).scrollTop = $(el).scrollHeight;
  //},
  received(data) {
    window.setTimeout(function() {
      console.log("-----> send chat")
      //var chat_id = 'chat_'+data.company_id + "_" + data.chat
      //var scroll_to_bottom = document.getElementById(chat_id);
      //console.log(scroll_to_bottom.scrollHeight)
      // Called when there's incoming data on the websocket for this channel
      if ($("#comment_" + data.comment_id).length){
        
      }else{
        $(data.chat_id).append(data.html)
        //message_list.append(data.html)
        console.log("end_message")
        window.setTimeout(function() {
          var scroll_to_bottom = document.getElementById('chat-content');
          scroll_to_bottom.scrollTop = (scroll_to_bottom.scrollHeight + 1300);
          console.log(scroll_to_bottom.scrollHeight)
        }, 500);
      }
      }, 250);
  }
});

//let submit_messages;
//
//$(document).on('turbolinks:load', function () {
//  submit_messages()
//})
//
//submit_messages = function () {
//  $('#message_content').on('keydown', function (event) {
//    if (event.keyCode == 13) {
//      $('input').click()
//      event.target.value = ''
//      event.preventDefault()
//    }
//  })
//}